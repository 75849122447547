var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "physicalRegister" },
    [
      _c(
        "Row",
        { staticStyle: { height: "100%", "flex-wrap": "nowrap" } },
        [
          _vm.expand
            ? _c(
                "Col",
                { style: _vm.leftSpan },
                [
                  _c(
                    "Card",
                    { staticStyle: { height: "100%", overflow: "hidden" } },
                    [
                      _c("Alert", { staticClass: "alert" }, [
                        _vm._v("人员查询"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _vm.physicalType == "从业体检"
                            ? _c(
                                "RadioGroup",
                                {
                                  staticStyle: {
                                    "margin-bottom": "10px",
                                    "margin-left": "5px",
                                  },
                                  on: { "on-change": _vm.feeStatusChange },
                                  model: {
                                    value: _vm.feeStatus,
                                    callback: function ($$v) {
                                      _vm.feeStatus = $$v
                                    },
                                    expression: "feeStatus",
                                  },
                                },
                                [
                                  _c("Radio", { attrs: { label: "未确认" } }),
                                  _c("Radio", { attrs: { label: "已确认" } }),
                                ],
                                1
                              )
                            : _c(
                                "RadioGroup",
                                {
                                  staticStyle: {
                                    "margin-bottom": "10px",
                                    "margin-left": "5px",
                                  },
                                  on: { "on-change": _vm.statusChange },
                                  model: {
                                    value: _vm.passStatus,
                                    callback: function ($$v) {
                                      _vm.passStatus = $$v
                                    },
                                    expression: "passStatus",
                                  },
                                },
                                [
                                  _c("Radio", { attrs: { label: "未登记" } }),
                                  _c("Radio", { attrs: { label: "已登记" } }),
                                ],
                                1
                              ),
                          _c(
                            "i-switch",
                            {
                              attrs: { size: "large" },
                              on: { "on-change": _vm.typeChange },
                              model: {
                                value: _vm.typeStatus,
                                callback: function ($$v) {
                                  _vm.typeStatus = $$v
                                },
                                expression: "typeStatus",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v("复查")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v("非复查")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                            "align-items": "center",
                          },
                        },
                        [
                          _vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "RadioGroup",
                                {
                                  staticClass: "btngroup",
                                  attrs: {
                                    type: "button",
                                    size: "small",
                                    "button-style": "solid",
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                [
                                  _c("Radio", {
                                    attrs: { label: "当日" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.radioClick("当日")
                                      },
                                    },
                                  }),
                                  _c("Radio", {
                                    attrs: { label: "当月" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.radioClick("当月")
                                      },
                                    },
                                  }),
                                  _c(
                                    "DatePicker",
                                    {
                                      attrs: {
                                        open: _vm.datePickerShow,
                                        transfer: "",
                                        type: "daterange",
                                      },
                                      on: { "on-change": _vm.datePickerChange },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          [
                                            _c("Radio", {
                                              attrs: { label: "自定义" },
                                              nativeOn: {
                                                click: [
                                                  function ($event) {
                                                    _vm.datePickerShow =
                                                      !_vm.datePickerShow
                                                  },
                                                  function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.radioClick(
                                                      "自定义"
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "span",
                                {
                                  staticClass: "resetBtn",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.resetClick },
                                },
                                [_vm._v("重置")]
                              )
                            : _vm._e(),
                          !_vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "nowrap",
                                    "align-items": "center",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c("DatePicker", {
                                    attrs: {
                                      type: "date",
                                      transfer: "",
                                      placeholder: "开始时间",
                                    },
                                    on: {
                                      "on-change": _vm.datePickerStartTime,
                                    },
                                    model: {
                                      value: _vm.searchForm.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startDate",
                                    },
                                  }),
                                  _vm._v("  ~  "),
                                  _c("DatePicker", {
                                    attrs: {
                                      type: "date",
                                      transfer: "",
                                      placeholder: "结束时间",
                                    },
                                    on: { "on-change": _vm.datePickerEndTime },
                                    model: {
                                      value: _vm.searchForm.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endDate", $$v)
                                      },
                                      expression: "searchForm.endDate",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("Input", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { placeholder: "请输入姓名", clearable: "" },
                        on: {
                          "on-clear": function ($event) {
                            return _vm.inputSearchChange("")
                          },
                        },
                        nativeOn: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.inputSearchChange.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.personName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "personName", $$v)
                          },
                          expression: "searchForm.personName",
                        },
                      }),
                      !_vm.queryTime
                        ? _c("Input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drop,
                                expression: "drop",
                              },
                            ],
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "idCard", $$v)
                              },
                              expression: "searchForm.idCard",
                            },
                          })
                        : _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "idCard", $$v)
                              },
                              expression: "searchForm.idCard",
                            },
                          }),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入体检编号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.testNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "testNum", $$v)
                              },
                              expression: "searchForm.testNum",
                            },
                          })
                        : _vm._e(),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入单位名称",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.dept,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "dept", $$v)
                              },
                              expression: "searchForm.dept",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.inputSearchChange },
                        },
                        [_vm._v("查询")]
                      ),
                      !_vm.queryTime &&
                      (_vm.passStatus == "已登记" || _vm.feeStatus == "已确认")
                        ? _c(
                            "Button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.resetClick },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                          _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                        ],
                        1
                      ),
                      _vm.personData.length > 0
                        ? _c(
                            "Row",
                            {
                              staticStyle: { "justify-content": "center" },
                              style: {
                                height:
                                  _vm.passStatus == "已登记"
                                    ? "calc(100% - " + _vm.height1 + ")"
                                    : "calc(100% - " + _vm.height2 + ")",
                              },
                            },
                            [
                              _c(
                                "RadioGroup",
                                {
                                  staticClass: "radio-group",
                                  model: {
                                    value: _vm.isRadioChecked,
                                    callback: function ($$v) {
                                      _vm.isRadioChecked = $$v
                                    },
                                    expression: "isRadioChecked",
                                  },
                                },
                                _vm._l(_vm.personData, function (item, index) {
                                  return _c(
                                    "Row",
                                    {
                                      key: index,
                                      staticClass: "row-border",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.radioChooseClick(
                                            item.id,
                                            item.registDate
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-radio",
                                          attrs: { span: 4 },
                                        },
                                        [
                                          _c("Radio", {
                                            attrs: {
                                              label: item.id,
                                              disabled: _vm.showRadio,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-content",
                                          attrs: { span: 15 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "80px",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                                "white-space": "nowrap",
                                              },
                                              attrs: { title: item.personName },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.personName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.sex))]
                                          ),
                                          _c("div", [_vm._v(_vm._s(item.age))]),
                                        ]
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 5 } },
                                        [
                                          _c(
                                            "Row",
                                            { attrs: { gutter: 6 } },
                                            [
                                              _c(
                                                "Col",
                                                { attrs: { span: 12 } },
                                                [
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "职业"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-blue",
                                                        },
                                                        [_vm._v(" 职 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "健康"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-green",
                                                        },
                                                        [_vm._v(" 健 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "从业"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-yellow",
                                                        },
                                                        [_vm._v(" 从 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "放射"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 放 ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 12 } },
                                                [
                                                  item.sporadicPhysical &&
                                                  item.sporadicPhysical == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 零 ")]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 团 ")]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _vm.personData.length > 0
                                ? _c("Page", {
                                    attrs: {
                                      current: _vm.searchForm.pageNumber,
                                      total: _vm.personTotal,
                                      simple: "",
                                    },
                                    on: {
                                      "on-change": _vm.personChangePageNum,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.personData.length == 0 ? _c("no-data") : _vm._e(),
                      _vm.checkPersonLoading
                        ? _c(
                            "Spin",
                            { attrs: { fix: "" } },
                            [
                              _c("Icon", {
                                staticClass: "demo-spin-icon-load",
                                attrs: { type: "ios-loading", size: "18" },
                              }),
                              _c("div", [_vm._v("加载中...")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            {
              staticStyle: { height: "100%", overflow: "hidden" },
              style: _vm.span,
            },
            [
              _vm.physicalType == "职业体检" || _vm.physicalType == "放射体检"
                ? _c(
                    "Card",
                    [
                      _c(
                        "Form",
                        {
                          ref: "personParam",
                          staticClass: "topForm",
                          attrs: {
                            model: _vm.personParam,
                            rules: _vm.personParamRule,
                          },
                        },
                        [
                          _c(
                            "Row",
                            {
                              staticStyle: { "flex-wrap": "nowrap" },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticStyle: { "min-width": "160px" },
                                  attrs: { span: "3" },
                                },
                                [
                                  _c("div", { staticClass: "pic" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pic-box",
                                        style: {
                                          border: _vm.personInfo.avatar
                                            ? "0 !important;"
                                            : "auto",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.photograph.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.personInfo.avatar
                                          ? _c("Icon", {
                                              attrs: {
                                                type: "ios-add",
                                                size: "24",
                                                color: "#2988f3",
                                              },
                                            })
                                          : [
                                              _c("img", {
                                                staticStyle: {
                                                  height: "100%",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  src: _vm.personInfo.avatar,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-upload-list-cover",
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-eye-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleView(
                                                          _vm.personInfo.avatar
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-trash-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleRemove()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                        !_vm.personInfo.avatar
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  height: "auto",
                                                  "line-height": "0",
                                                  "margin-top": "30px",
                                                  color: "#2988f3",
                                                },
                                              },
                                              [_vm._v("人脸采集")]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "Col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "space-between",
                                    "margin-left": "-20px",
                                  },
                                  attrs: { span: "21" },
                                },
                                [
                                  _c(
                                    "Row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c("Col", { attrs: { span: "24" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "wrap" },
                                          [
                                            _c("step", {
                                              attrs: {
                                                index: _vm.currentIndex,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检编号" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "提交后系统自动生成",
                                                  clearable: "",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.testNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "testNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.testNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "身份证号",
                                                prop: "idCard",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入身份证号",
                                                },
                                                on: {
                                                  "on-blur": _vm.idCardChange,
                                                },
                                                model: {
                                                  value: _vm.personParam.idCard,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "idCard",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.idCard",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("姓名"),
                                                prop: "personName",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.personName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "personName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.personName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("性别"),
                                                prop: "sex",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "性别",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.sex",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("年龄"),
                                                prop: "age",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "年龄",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.age,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "age",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.age",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "体检类型",
                                                prop: "physicalType",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "体检类型",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam
                                                      .physicalType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "physicalType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.physicalType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "联系电话",
                                                prop: "mobile",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.personParam.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _vm.isUpdateUnitName
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "单位名称",
                                                    prop: "unitId",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.onOrderSelectChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .unitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "unitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.unitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.unitArr,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              tag: index,
                                                              label: item.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isUpdateUnitNameGroup
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "单位名称",
                                                    prop: "unitId",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.onOrderSelectChangeGroup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .unitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "unitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.unitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.orderArr,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              label:
                                                                item.groupUnitName,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.groupUnitName +
                                                                    "-" +
                                                                    item.orderCode
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isUpdateUnitName &&
                                          !_vm.isUpdateUnitNameGroup
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "单位名称",
                                                    prop: "unitId",
                                                  },
                                                },
                                                [
                                                  _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      readonly: true,
                                                      placeholder: "单位名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam.dept,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "dept",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.dept",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "危害因素" } },
                                            [
                                              !_vm.isUpdateUnitNameGroup
                                                ? _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "选择套餐后自动生成",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .hazardFactorsText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "hazardFactorsText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.hazardFactorsText",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.isUpdateUnitNameGroup
                                                ? _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "选择分组后自动填充",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .hazardFactorsText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "hazardFactorsText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.hazardFactorsText",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.personParam.hazardFactors &&
                                      (_vm.personParam.hazardFactors.indexOf(
                                        "110999"
                                      ) > -1 ||
                                        _vm.personParam.hazardFactors.indexOf(
                                          "120999"
                                        ) > -1 ||
                                        _vm.personParam.hazardFactors.indexOf(
                                          "130999"
                                        ) > -1 ||
                                        _vm.personParam.hazardFactors.indexOf(
                                          "140999"
                                        ) > -1 ||
                                        _vm.personParam.hazardFactors.indexOf(
                                          "150999"
                                        ) > -1 ||
                                        _vm.personParam.hazardFactors.indexOf(
                                          "160999"
                                        ) > -1)
                                        ? _c(
                                            "Col",
                                            { attrs: { span: "6" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "其他危害因素",
                                                    prop: "otherHazardFactors",
                                                  },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "请输入其他危害因素名称，多个逗号“，”分隔",
                                                      readonly:
                                                        _vm.personParam
                                                          .isPass == 2,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .otherHazardFactors,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "otherHazardFactors",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.otherHazardFactors",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "工种名称",
                                                prop: "workTypeText",
                                              },
                                            },
                                            [
                                              _vm.searchForm.isPass == 1
                                                ? _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      disabled:
                                                        _vm.personParam
                                                          .isPass == 2,
                                                      placeholder: "工种名称",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleWorkCode.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .workTypeText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "workTypeText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.workTypeText",
                                                    },
                                                  })
                                                : _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      readonly: true,
                                                      placeholder: "工种名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .workTypeText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "workTypeText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.workTypeText",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.personParam.workTypeCode &&
                                      (_vm.personParam.workTypeCode.indexOf(
                                        "0014"
                                      ) > -1 ||
                                        _vm.personParam.workTypeCode.indexOf(
                                          "0033"
                                        ) > -1 ||
                                        _vm.personParam.workTypeCode.indexOf(
                                          "999999"
                                        ) > -1)
                                        ? _c(
                                            "Col",
                                            { attrs: { span: "6" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "工种其他名称",
                                                    prop: "workName",
                                                  },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "请输入工种其他名称",
                                                      readonly:
                                                        _vm.personParam
                                                          .isPass == 2,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .workName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "workName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.workName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "检查种类" } },
                                            [
                                              !_vm.isUpdateUnitNameGroup
                                                ? _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "选择套餐后自动生成",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .workStateText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "workStateText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.workStateText",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.isUpdateUnitNameGroup
                                                ? _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "选择分组后自动生成",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .workStateText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "workStateText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.workStateText",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _vm.personParam.sporadicPhysical &&
                                          _vm.personParam.sporadicPhysical ==
                                            "1"
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "分组名称" },
                                                },
                                                [
                                                  _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: true,
                                                      placeholder:
                                                        "选择套餐后自动生成",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .groupName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "groupName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.groupName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "分组名称" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        placeholder:
                                                          "请先选择单位",
                                                        filterable: true,
                                                        clearable: "",
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-clear":
                                                          _vm.clearGroupId,
                                                        "on-select":
                                                          _vm.changeGroup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .groupId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "groupId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.groupId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.groupByOrderData,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              tag: item,
                                                              label: item.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "婚姻状态" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.isMarry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "isMarry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.isMarry",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.marriageArr,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.title,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.passStatus == "已登记"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "登记时间" },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: { disabled: true },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .regist_date ||
                                                        _vm.personInfo
                                                          .registDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo
                                                            .regist_date ||
                                                            _vm.personInfo,
                                                          "registDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.regist_date || personInfo.registDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Row",
                            {
                              staticStyle: { "margin-top": "15px" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "Col",
                                [
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "warning",
                                            icon: "ios-create-outline",
                                            disabled: !_vm.personInfo.id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.updateShow = true
                                            },
                                          },
                                        },
                                        [_vm._v("修改信息 ")]
                                      )
                                    : _vm._e(),
                                  _vm.isShowAloneAddButton &&
                                  _vm.searchForm.isPass != 99 &&
                                  !_vm.typeStatus &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-add",
                                          },
                                          on: { click: _vm.addHealthPerson },
                                        },
                                        [_vm._v("零星新增 ")]
                                      )
                                    : _vm._e(),
                                  _vm.searchForm.isPass != 99 &&
                                  !_vm.typeStatus &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-add",
                                          },
                                          on: {
                                            click: _vm.addHealthPersonGroup,
                                          },
                                        },
                                        [_vm._v("团检新增 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  ((_vm.personInfo && _vm.personInfo.id) ||
                                    (_vm.personData &&
                                      _vm.personData.length == 0))
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "error",
                                            icon: "md-trash",
                                            disabled: !_vm.personInfo.id,
                                          },
                                          on: { click: _vm.deleteTGroupPerson },
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.readShow,
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.readIdCard },
                                    },
                                    [_vm._v(" 读取二代身份证 ")]
                                  ),
                                  !_vm.typeStatus &&
                                  (_vm.saveShow || _vm.personParam.id) &&
                                  _vm.passStatus == "未登记"
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            loading: _vm.saveLoading,
                                            type: "primary",
                                            icon: "md-play",
                                          },
                                          on: { click: _vm.saveHealthPerson },
                                        },
                                        [_vm._v("保存信息 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.showPrint &&
                                  _vm.personInfo.avatar &&
                                  _vm.personInfo.id &&
                                  !_vm.showPrint &&
                                  _vm.personInfo.id &&
                                  _vm.searchForm.isPass == 1 &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-clipboard",
                                          },
                                          on: { click: _vm.updatePerson },
                                        },
                                        [_vm._v(" 确认登记 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  ((_vm.showPrint && _vm.personInfo.avatar) ||
                                    _vm.searchForm.isPass == 99 ||
                                    _vm.isReviewer ||
                                    _vm.isShowRegisterButton)
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-print",
                                          },
                                          on: {
                                            click: _vm.printInspectionSheet,
                                          },
                                        },
                                        [_vm._v(" 打印导检单 ")]
                                      )
                                    : _vm._e(),
                                  ((_vm.showPrint && _vm.personInfo.avatar) ||
                                    _vm.searchForm.isPass == 99) &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "md-checkbox-outline",
                                          },
                                          on: { click: _vm.handleComplete },
                                        },
                                        [_vm._v(" 收单 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.physicalType == "从业体检"
                ? _c(
                    "Card",
                    [
                      _c(
                        "Form",
                        {
                          ref: "personParam",
                          staticClass: "personForm",
                          attrs: {
                            model: _vm.personParam,
                            rules: _vm.personParamRule,
                            "in-line": "",
                            "label-width": 80,
                          },
                        },
                        [
                          _c(
                            "Row",
                            {
                              staticStyle: { "flex-wrap": "nowrap" },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticStyle: { "min-width": "160px" },
                                  attrs: { span: "3" },
                                },
                                [
                                  _c("div", { staticClass: "pic" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pic-box",
                                        style: {
                                          border: _vm.personParam.avatar
                                            ? "0 !important;"
                                            : "auto",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.photograph.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.personParam.avatar
                                          ? _c("Icon", {
                                              attrs: {
                                                type: "ios-add",
                                                size: "24",
                                                color: "#2988f3",
                                              },
                                            })
                                          : [
                                              _c("img", {
                                                staticStyle: {
                                                  height: "100%",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  src: _vm.personParam.avatar,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-upload-list-cover",
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-eye-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleView(
                                                          _vm.personParam.avatar
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-trash-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleRemove()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                        !_vm.personParam.avatar
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  height: "auto",
                                                  "line-height": "0",
                                                  "margin-top": "30px",
                                                  color: "#2988f3",
                                                },
                                              },
                                              [_vm._v("人脸采集")]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "Col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "space-between",
                                    "margin-left": "-20px",
                                  },
                                  attrs: { span: "21" },
                                },
                                [
                                  _c(
                                    "Row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c("Col", { attrs: { span: "24" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "wrap" },
                                          [
                                            _c("step", {
                                              attrs: {
                                                index: _vm.currentIndex,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检编号" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "提交后系统自动生成",
                                                  clearable: "",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.testNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "testNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.testNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "身份证号",
                                                prop: "idCard",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入身份证号",
                                                },
                                                on: {
                                                  "on-blur": _vm.idCardChange,
                                                },
                                                model: {
                                                  value: _vm.personParam.idCard,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "idCard",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.idCard",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("姓名"),
                                                prop: "personName",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.personName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "personName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.personName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("性别"),
                                                prop: "sex",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "性别",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.sex",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("年龄"),
                                                prop: "age",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "年龄",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.age,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "age",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.age",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "体检类型",
                                                prop: "physicalType",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "体检类型",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam
                                                      .physicalType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "physicalType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.physicalType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "联系电话",
                                                prop: "mobile",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.personParam.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _vm.isUpdateUnitName
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "单位名称" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.unitSelect,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .groupUnitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "groupUnitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.groupUnitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.unitArr,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isUpdateUnitName
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "单位名称",
                                                    prop: "unitId",
                                                  },
                                                },
                                                [
                                                  _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      readonly: true,
                                                      placeholder: "单位名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam.dept,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "dept",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.dept",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "婚姻状态" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.isMarry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "isMarry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.isMarry",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.marriageArr,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.title,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "从业类别" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam
                                                        .certificateType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "certificateType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.certificateType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.certificateTypeArr,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: item.value,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "民族",
                                                prop: "nation",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.nation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "nation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.nation",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.nationArr,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.feeStatus == "已确认"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "登记时间" },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: { disabled: true },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .regist_date ||
                                                        _vm.personInfo
                                                          .registDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo
                                                            .regist_date ||
                                                            _vm.personInfo,
                                                          "registDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.regist_date || personInfo.registDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Row",
                            {
                              staticStyle: { "margin-top": "15px" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "Col",
                                [
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "warning",
                                            icon: "ios-create-outline",
                                            disabled: _vm.personInfo.id
                                              ? false
                                              : true,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.updateShow = true
                                            },
                                          },
                                        },
                                        [_vm._v("修改信息 ")]
                                      )
                                    : _vm._e(),
                                  _vm.searchForm.isPass != 99 &&
                                  !_vm.typeStatus &&
                                  !_vm.isUpdateUnitName
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-add",
                                          },
                                          on: { click: _vm.addPerson },
                                        },
                                        [_vm._v("新增 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  ((_vm.personInfo && _vm.personInfo.id) ||
                                    (_vm.personData &&
                                      _vm.personData.length == 0))
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "error",
                                            icon: "md-trash",
                                            disabled: !_vm.personInfo.id,
                                          },
                                          on: { click: _vm.deleteTGroupPerson },
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        loading: _vm.readShow,
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.readIdCard },
                                    },
                                    [_vm._v(" 读取二代身份证 ")]
                                  ),
                                  _vm.passStatus == "未登记" &&
                                  !_vm.typeStatus &&
                                  _vm.searchForm.isPass != 99 &&
                                  (_vm.saveShow || _vm.personInfo.id)
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            loading: _vm.saveLoading,
                                            type: "primary",
                                            icon: "md-play",
                                          },
                                          on: { click: _vm.savePersonInfo },
                                        },
                                        [_vm._v("保存信息 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.showPrint &&
                                  _vm.personInfo.id &&
                                  _vm.personParam.avatar &&
                                  _vm.searchForm.isPass == 1 &&
                                  !_vm.isReviewer &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-clipboard",
                                          },
                                          on: {
                                            click: _vm.updateRegisterPerson,
                                          },
                                        },
                                        [_vm._v(" 确认登记 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.showPrint &&
                                  _vm.personInfo.id &&
                                  _vm.personParam.avatar &&
                                  _vm.searchForm.isPass == 1 &&
                                  _vm.isReviewer &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-clipboard",
                                          },
                                          on: { click: _vm.updatePerson },
                                        },
                                        [_vm._v(" 确认登记 ")]
                                      )
                                    : _vm._e(),
                                  ((_vm.showPrint &&
                                    _vm.searchForm.isPass == 1) ||
                                    _vm.searchForm.isPass == 99 ||
                                    _vm.isReviewer ||
                                    _vm.isShowRegisterButton) &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-print",
                                          },
                                          on: {
                                            click: _vm.printInspectionSheet,
                                          },
                                        },
                                        [_vm._v(" 打印导检单 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  _vm.isShowConfirmPayment &&
                                  _vm.showPrint &&
                                  _vm.personParam.avatar &&
                                  _vm.orderInfo.payStatus == 0 &&
                                  !_vm.payStatus &&
                                  (_vm.searchForm.isPass == 99 ||
                                    _vm.searchForm.isPass == 1)
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "success",
                                            icon: "ios-clipboard",
                                          },
                                          on: { click: _vm.updateOrderInfo },
                                        },
                                        [_vm._v(" 确认缴费 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.physicalType == "健康体检"
                ? _c(
                    "Card",
                    [
                      _c(
                        "Form",
                        {
                          ref: "personParam",
                          staticClass: "personForm",
                          attrs: {
                            model: _vm.personParam,
                            rules: _vm.personParamRule,
                            "in-line": "",
                            "label-width": 80,
                          },
                        },
                        [
                          _c(
                            "Row",
                            {
                              staticStyle: { "flex-wrap": "nowrap" },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticStyle: { "min-width": "160px" },
                                  attrs: { span: "3" },
                                },
                                [
                                  _c("div", { staticClass: "pic" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pic-box",
                                        style: {
                                          border: _vm.personParam.avatar
                                            ? "0 !important;"
                                            : "auto",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.photograph.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.personParam.avatar
                                          ? _c("Icon", {
                                              attrs: {
                                                type: "ios-add",
                                                size: "24",
                                                color: "#2988f3",
                                              },
                                            })
                                          : [
                                              _c("img", {
                                                staticStyle: {
                                                  height: "100%",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  src: _vm.personParam.avatar,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-upload-list-cover",
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-eye-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleView(
                                                          _vm.personParam.avatar
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "ios-trash-outline",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleRemove()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                        !_vm.personParam.avatar
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  height: "auto",
                                                  "line-height": "0",
                                                  "margin-top": "30px",
                                                  color: "#2988f3",
                                                },
                                              },
                                              [_vm._v("人脸采集")]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "Col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "space-between",
                                    "margin-left": "-20px",
                                  },
                                  attrs: { span: "21" },
                                },
                                [
                                  _c(
                                    "Row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c("Col", { attrs: { span: "24" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "wrap" },
                                          [
                                            _c("step", {
                                              attrs: {
                                                index: _vm.currentIndex,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检编号" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "提交后系统自动生成",
                                                  clearable: "",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.testNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "testNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.testNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "身份证号",
                                                prop: "idCard",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入身份证号",
                                                },
                                                on: {
                                                  "on-blur": _vm.idCardChange,
                                                },
                                                model: {
                                                  value: _vm.personParam.idCard,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "idCard",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.idCard",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("姓名"),
                                                prop: "personName",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "请输入姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.personName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "personName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.personName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("性别"),
                                                prop: "sex",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "性别",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.sex",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("年龄"),
                                                prop: "age",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "年龄",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.personParam.age,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "age",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personParam.age",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "体检类型",
                                                prop: "physicalType",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "体检类型",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam
                                                      .physicalType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "physicalType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.physicalType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "联系电话",
                                                prop: "mobile",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly:
                                                    _vm.searchForm.isPass == 99
                                                      ? true
                                                      : false,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.personParam.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _vm.isUpdateUnitName
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "单位名称" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.onOrderSelectChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .unitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "unitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.unitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.unitArr,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              tag: item.groupUnitId,
                                                              label: item.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isUpdateUnitNameGroup
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "单位名称",
                                                    prop: "unitId",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.onOrderSelectChangeGroup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .unitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "unitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.unitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.orderArr,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              label:
                                                                item.groupUnitName,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.groupUnitName +
                                                                    "-" +
                                                                    item.orderCode
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isUpdateUnitName &&
                                          !_vm.isUpdateUnitNameGroup
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "单位名称" },
                                                },
                                                [
                                                  _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      readonly: true,
                                                      placeholder: "单位名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam.dept,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "dept",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.dept",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "婚姻状态" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.isMarry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "isMarry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.isMarry",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.marriageArr,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.title,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "6" } },
                                        [
                                          _vm.personParam.sporadicPhysical &&
                                          _vm.personParam.sporadicPhysical ==
                                            "1"
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "分组名称" },
                                                },
                                                [
                                                  _c("Input", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "请输入分组名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personParam
                                                          .groupName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personParam,
                                                          "groupName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personParam.groupName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "分组名称" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        placeholder:
                                                          "请先选择单位",
                                                        filterable: true,
                                                        clearable: "",
                                                        readonly:
                                                          _vm.searchForm
                                                            .isPass == 99
                                                            ? true
                                                            : false,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-clear":
                                                          _vm.clearGroupId,
                                                        "on-select":
                                                          _vm.changeGroup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personParam
                                                            .groupId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personParam,
                                                            "groupId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personParam.groupId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.groupByOrderData,
                                                      function (item, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: item.id,
                                                              tag: item,
                                                              label: item.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                      _vm.passStatus == "已登记"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "登记时间" },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: { disabled: true },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .regist_date ||
                                                        _vm.personInfo
                                                          .registDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo
                                                            .regist_date ||
                                                            _vm.personInfo,
                                                          "registDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.regist_date || personInfo.registDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "微信单号" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "微信订单号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam
                                                      .weixinOrderId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "weixinOrderId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.weixinOrderId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Row",
                            {
                              staticStyle: { "margin-top": "15px" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "Col",
                                [
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "warning",
                                            icon: "ios-create-outline",
                                            disabled: _vm.personInfo.id
                                              ? false
                                              : true,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.updateShow = true
                                            },
                                          },
                                        },
                                        [_vm._v("修改信息 ")]
                                      )
                                    : _vm._e(),
                                  _vm.isShowAloneAddButton &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  _vm.searchForm.isPass != 99 &&
                                  !_vm.typeStatus
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-add",
                                          },
                                          on: { click: _vm.addHealthPerson },
                                        },
                                        [_vm._v("零星新增 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  _vm.searchForm.isPass != 99 &&
                                  !_vm.typeStatus
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-add",
                                          },
                                          on: {
                                            click: _vm.addHealthPersonGroup,
                                          },
                                        },
                                        [_vm._v("团检新增 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        loading: _vm.saveLoading,
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addWeixinOrder },
                                    },
                                    [_vm._v("小程序预约")]
                                  ),
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup &&
                                  ((_vm.personInfo && _vm.personInfo.id) ||
                                    (_vm.personData &&
                                      _vm.personData.length == 0))
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "error",
                                            icon: "md-trash",
                                            disabled: !_vm.personInfo.id,
                                          },
                                          on: { click: _vm.deleteTGroupPerson },
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        loading: _vm.readShow,
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.readIdCard },
                                    },
                                    [_vm._v(" 读取二代身份证 ")]
                                  ),
                                  _vm.passStatus == "未登记" &&
                                  !_vm.typeStatus &&
                                  (_vm.saveShow || _vm.personParam.id)
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            loading: _vm.saveLoading,
                                            type: "primary",
                                            icon: "md-play",
                                          },
                                          on: { click: _vm.saveHealthPerson },
                                        },
                                        [_vm._v("保存信息 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      attrs: {
                                        loading: _vm.saveLoading,
                                        type: "primary",
                                        icon: "md-play",
                                      },
                                      on: { click: _vm.addPersonItem },
                                    },
                                    [_vm._v("加项保存")]
                                  ),
                                  !_vm.showPrint &&
                                  _vm.personParam.avatar &&
                                  _vm.personParam.id &&
                                  _vm.searchForm.isPass == 1 &&
                                  !_vm.isReviewer &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-clipboard",
                                          },
                                          on: {
                                            click: _vm.updateRegisterPerson,
                                          },
                                        },
                                        [_vm._v(" 确认登记 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.showPrint &&
                                  _vm.personParam.avatar &&
                                  _vm.personParam.id &&
                                  _vm.searchForm.isPass == 1 &&
                                  _vm.isReviewer &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-clipboard",
                                          },
                                          on: { click: _vm.updatePerson },
                                        },
                                        [_vm._v(" 确认登记 ")]
                                      )
                                    : _vm._e(),
                                  ((_vm.showPrint &&
                                    _vm.searchForm.isPass == 1) ||
                                    _vm.searchForm.isPass == 99 ||
                                    _vm.isReviewer ||
                                    _vm.isShowRegisterButton) &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "primary",
                                            icon: "md-print",
                                          },
                                          on: {
                                            click: _vm.printInspectionSheet,
                                          },
                                        },
                                        [_vm._v(" 打印导检单 ")]
                                      )
                                    : _vm._e(),
                                  _vm.showPrint &&
                                  _vm.personParam.avatar &&
                                  !_vm.payStatus &&
                                  !_vm.typeStatus &&
                                  _vm.isShowConfirmPayment &&
                                  !_vm.isUpdateUnitName &&
                                  !_vm.isUpdateUnitNameGroup
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: { width: "110px" },
                                          attrs: {
                                            type: "success",
                                            icon: "ios-clipboard",
                                          },
                                          on: { click: _vm.updateIsPass },
                                        },
                                        [_vm._v(" 确认缴费 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.physicalType == "从业体检"
                ? _c(
                    "Card",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        height: "calc(100% - 241px)",
                        overflow: "auto",
                      },
                    },
                    [
                      _c(
                        "Alert",
                        {
                          staticClass: "ivu-alert-left",
                          staticStyle: {
                            height: "40px",
                            "line-height": "23px",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v("体检项目")]
                          ),
                          _vm.personParam.comboName
                            ? _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.personParam.comboName + ")"
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.feeStatus == "未确认" &&
                          _vm.passStatus == "未登记"
                            ? _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "md-add",
                                    size: "small",
                                  },
                                  on: { click: _vm.handleComboSelect },
                                },
                                [_vm._v("套餐类型 ")]
                              )
                            : _vm._e(),
                          _vm.feeStatus == "未确认" &&
                          _vm.passStatus == "未登记"
                            ? _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "md-add",
                                    size: "small",
                                  },
                                  on: { click: _vm.otherItemSelect },
                                },
                                [_vm._v("选检项目 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("Table", {
                        ref: "table",
                        attrs: {
                          loading: _vm.projectLoading,
                          border: "",
                          columns: _vm.projectColumns,
                          sortable: "custom",
                          data: _vm.groupData[0].projectData,
                          "max-height": _vm.tableHeight,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "action",
                              fn: function ({ row, index }) {
                                return _vm.personParam.isPass == 1
                                  ? [
                                      _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "error",
                                            size: "small",
                                            icon: "md-trash",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.otherItemDelete(
                                                row,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c(
                        "Alert",
                        {
                          staticClass: "ivu-alert-left",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [_vm._v(" 费用信息 ")]
                      ),
                      _c(
                        "Form",
                        [
                          _c(
                            "Row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "Col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "套餐内费用：" } },
                                    [
                                      _vm._v(
                                        " ￥" +
                                          _vm._s(this.orderInfo.packageTotal) +
                                          "元 "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "体检费用合计：" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "18px",
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(
                                                this.orderInfo.orderTotal
                                              ) +
                                              "元"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "优惠价格：" } },
                                    [
                                      _c("InputNumber", {
                                        attrs: { min: 0 },
                                        model: {
                                          value: _vm.tGroupData.discountPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupData,
                                              "discountPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupData.discountPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.typeStatus
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ivu-alert ivu-alert-info ivu-alert-with-icon",
                              staticStyle: { "margin-top": "16px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    margin: "5px 0 10px 0",
                                    "font-weight": "600",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 操作说明 ")]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第一步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 点击新增（初始化表单） ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第二步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 填写信息（填写体检人员信息） ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第三步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [
                                      _vm._v(
                                        " 点击必检类型选择（选择必检项目） "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第四步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 点击保存（保存体检人员信息） ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第五步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 采集人像（采集体检人员头像） ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第六步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 确认登记 ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第七步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [
                                      _vm._v(
                                        " 打印导检单（体检人员可以根据导检单进行体检） "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第八步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 确认缴费 ")]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "ivu-alert ivu-alert-info ivu-alert-with-icon",
                              staticStyle: { "margin-top": "16px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    margin: "5px 0",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 操作说明 ")]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第一步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 确认登记 ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第二步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [
                                      _vm._v(
                                        " 打印导检单（体检人员可以根据导检单进行体检） "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "explain" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 第三步： ")]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ivu-alert-message" },
                                    [_vm._v(" 确认缴费 ")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.physicalType == "健康体检" ||
              _vm.physicalType == "职业体检" ||
              _vm.physicalType == "放射体检"
                ? _c("healthCheckup", {
                    ref: "healthCheckup",
                    staticStyle: { overflow: "auto", "margin-top": "0px" },
                    attrs: {
                      isUpdateUnitName: _vm.isUpdateUnitName,
                      isUpdateUnitNameGroup: _vm.isUpdateUnitNameGroup,
                      personInfo: _vm.personParam,
                      "type-status": _vm.typeStatus,
                      projectDataInfo: _vm.data,
                      showSelectButton: _vm.showSelectButton,
                    },
                    on: { handleSearchCheckup: _vm.handleSearchCheckup },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("inspection-sheet", {
            ref: "inspection",
            attrs: {
              personInfo: _vm.personInfo,
              projectInfo: _vm.data,
              orderTotal: _vm.orderInfo.orderTotal,
              isReviewer: _vm.isReviewer,
              passStatus: _vm.passStatus,
              data: _vm.data,
            },
            on: {
              handleSearch: function ($event) {
                return _vm.inputSearchChange("")
              },
            },
            model: {
              value: _vm.printSheet,
              callback: function ($$v) {
                _vm.printSheet = $$v
              },
              expression: "printSheet",
            },
          }),
          _c("photograph", {
            on: { handleSearch: _vm.handleSetImg },
            model: {
              value: _vm.showPhotograph,
              callback: function ($$v) {
                _vm.showPhotograph = $$v
              },
              expression: "showPhotograph",
            },
          }),
          _c("choose-portfolio-project", {
            attrs: { checkedList: _vm.choosePortfolioProjectData },
            on: { handleSearch: _vm.setTableData },
            model: {
              value: _vm.choosePortfolioProjectShow,
              callback: function ($$v) {
                _vm.choosePortfolioProjectShow = $$v
              },
              expression: "choosePortfolioProjectShow",
            },
          }),
          _c("chooseWorkType", {
            on: { workCodeReback: _vm.workCodeReback },
            model: {
              value: _vm.workShow,
              callback: function ($$v) {
                _vm.workShow = $$v
              },
              expression: "workShow",
            },
          }),
          _c("harmDrawer", {
            attrs: {
              modalTitle: "危害因素选择",
              selectedHarmList: _vm.selectedHarmList,
              selectType: _vm.seltType,
            },
            on: { harmReback: _vm.harmReback },
            model: {
              value: _vm.harmShow,
              callback: function ($$v) {
                _vm.harmShow = $$v
              },
              expression: "harmShow",
            },
          }),
          _c("updateTGroupPerson", {
            attrs: {
              tGroupPersonId: _vm.personInfo.id,
              modalTitle: "人员信息修改",
              orderId: _vm.personInfo.orderId,
              groupId: _vm.personInfo.groupId,
            },
            on: {
              handleSearch: function ($event) {
                return _vm.queryPersonData(_vm.personInfo.id, 1)
              },
            },
            model: {
              value: _vm.updateShow,
              callback: function ($$v) {
                _vm.updateShow = $$v
              },
              expression: "updateShow",
            },
          }),
          _c("combo-drawer", {
            attrs: { modalTitle: "从业套餐选择", comboId: _vm.comboId },
            on: { selCallBack: _vm.selCallBack },
            model: {
              value: _vm.comboDrawerShow,
              callback: function ($$v) {
                _vm.comboDrawerShow = $$v
              },
              expression: "comboDrawerShow",
            },
          }),
          _c("weixin-drawer", {
            attrs: { modalTitle: "微信订单", comboId: _vm.comboId },
            on: { selCallBack: _vm.selWeixinCallBack },
            model: {
              value: _vm.weixinDrawerShow,
              callback: function ($$v) {
                _vm.weixinDrawerShow = $$v
              },
              expression: "weixinDrawerShow",
            },
          }),
          _c("choose-portfolio-project", {
            attrs: { checkedList: _vm.itemSelectedData },
            on: { handleSearch: _vm.handleSearch },
            model: {
              value: _vm.isDrawerShow,
              callback: function ($$v) {
                _vm.isDrawerShow = $$v
              },
              expression: "isDrawerShow",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }